import { UserRole } from "../../../../shared/enums/userRoles.enum";
import { Company } from "../../../../shared/models/company.model";
import { Department } from "../../../../shared/models/department.model";
import { Forklift } from "../../../../shared/models/forklift.model";
import { Device } from "../../../admin-pages/admin-view/models/device.model";
import { Operator } from "../../../admin-pages/admin-view/models/operator.model";
import { ForkliftTag } from "../../../admin-pages/admin-view/models/tag.model";
import { User } from "../../../admin-pages/admin-view/models/user.model";
import { AlarmSets } from "../../../alarms/alarm-sets/models/alarm-sets.model";
import { Alarm } from "../../../alarms/alarm-table/models/alarm.model";

export interface AuthInitialState {
    token: string | null;
    userName: string | null;
    userId: string;
    role: UserRole;
    companies: Company[];
    departments: Department[];
    forklifts: Forklift[];
    devices: Device[];
    operators: Operator[];
    tags: ForkliftTag[];
    users: User[];
    alarms: Alarm[];
    alarmSets: AlarmSets[];
    user: User | null;
    passwordRecoveryStep: number | null;
    userEmail: string | null;
}

const initialAuthState: AuthInitialState = {
    token: null,
    userName: null,
    userId: "",
    role: UserRole.Admin,
    companies: [],
    departments: [],
    forklifts: [],
    operators: [],
    user: null,
    passwordRecoveryStep: null,
    userEmail: null,
    devices: [],
    tags: [],
    users: [],
    alarms: [],
    alarmSets: []
}

export default initialAuthState