import { AdminViewType } from "../../../../shared/enums/adminViewType.enum";
import { Company } from "../models/company.model";
import { Department } from "../models/department.model";
import { Device } from "../models/device.model";
import { Forklift } from "../models/forklift.model";
import { Operator } from "../models/operator.model";
import { ForkliftTag } from "../models/tag.model";
import { User } from "../models/user.model";

export interface adminViewInitialState {
    showFilters: boolean;
    selectedCompany: string;
    selectedView: AdminViewType;
    companyList: Company[];
    departmentList: Department[];
    deviceList: Device[];
    userList: User[];
    forkliftList: Forklift[];
    operatorList: Operator[];
    tagList: ForkliftTag[];
}

const initialAdminView: adminViewInitialState = {
    showFilters: true,
    selectedCompany: "0",
    selectedView: AdminViewType.Company,
    companyList: [],
    departmentList: [],
    deviceList: [],
    userList: [],
    forkliftList: [],
    operatorList: [],
    tagList: []
}

export default initialAdminView