import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialAdminViewSliceState from './admin-view-initial-state';
import { AdminViewType } from '../../../../shared/enums/adminViewType.enum';
import { Company } from '../models/company.model';
import { Department } from '../models/department.model';
import { Forklift } from '../models/forklift.model';
import { User } from '../models/user.model';
import { Device } from '../models/device.model';
import { Operator } from '../models/operator.model';
import { ForkliftTag } from '../models/tag.model';

const adminViewSlice = createSlice({
    name: 'adminViewSlice',
    initialState: initialAdminViewSliceState,
    reducers: {
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        },
        setSelectedCompany(state, { payload }: PayloadAction<string>) {
            state.selectedCompany = payload;
        },
        setSelectedAdminView(state, { payload }: PayloadAction<AdminViewType>) {
            state.selectedView = payload;
        },
        setCompanyList(state, { payload }: PayloadAction<Company[]>) {
            state.companyList = payload;
        },
        setDepartmentList(state, { payload }: PayloadAction<Department[]>) {
            state.departmentList = payload;
        },
        setForkliftList(state, { payload }: PayloadAction<Forklift[]>) {
            state.forkliftList = payload;
        },
        setUserList(state, { payload }: PayloadAction<User[]>) {
            state.userList = payload;
        },
        setDeviceList(state, { payload }: PayloadAction<Device[]>) {
            state.deviceList = payload;
        },
        setOperatorList(state, { payload }: PayloadAction<Operator[]>) {
            state.operatorList = payload;
        },
        setTagList(state, { payload }: PayloadAction<ForkliftTag[]>) {
            state.tagList = payload;
        },
    }
});

export const {
    setShowFilters,
    setSelectedCompany,
    setSelectedAdminView,
    setCompanyList,
    setDepartmentList,
    setForkliftList,
    setUserList,
    setDeviceList,
    setOperatorList,
    setTagList
} = adminViewSlice.actions

export default adminViewSlice.reducer